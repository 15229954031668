import { AceItem } from '../../typings/autogen'

export function orderToCsv(order: ReadonlyArray<AceItem>) {
  const headers = 'Previews Code,Title,Price,Publisher'

  const rows = order.map(i => {
    return [
      i.previewsCode,
      i.title,
      i.price,
      i.publisher
    ].join(',')
  })
  const output = [
    headers,
    ...rows,
    `,,Total,${order.reduce((acc: number, curr: AceItem) => acc += curr.price, 0).toFixed(2)}`,
    'Generated by Ace My Order'
  ].join('\n')

  return output
}
